import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar(props) {
    return (
        <nav id="sidebar" className="sidebar js-sidebar">
            <div className="sidebar-content js-simplebar">
                <a className="sidebar-brand" href="index.html">
                    <span className="align-middle">Le Céladon Manager</span>
                </a>

                <ul className="sidebar-nav">
                    <li className="sidebar-header">
                        Pages
                    </li>

                    <li className="sidebar-item active">
                        <Link className="sidebar-link" to={"/"}>
                               <i className="align-middle" data-feather="sliders"></i> <span className="align-middle">Dashboard</span>
                        </Link>
                    </li>

                    <li className="sidebar-item">
                        <Link className="sidebar-link" to="/cmiExcel">
                        <i className="align-middle" data-feather="user"></i> <span className="align-middle">Cmi Excel</span>
                        </Link>                       
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="/tracker">
                            <i className="align-middle" data-feather="log-in"></i> <span className="align-middle">Track Vehicules</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="pages-sign-up.html">
                            <i className="align-middle" data-feather="user-plus"></i> <span className="align-middle">Sign Up</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="pages-blank.html">
                            <i className="align-middle" data-feather="book"></i> <span className="align-middle">Blank</span>
                        </a>
                    </li>

                    <li className="sidebar-header">
                        Products
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="/addproductsite">
                            <i className="align-middle" data-feather="square"></i> <span className="align-middle">Ajouter Produit WebSite</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="/productList">
                            <i className="align-middle" data-feather="check-square"></i> <span className="align-middle">Voir les produits</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="/ordersGlovo">
                            <i className="align-middle" data-feather="grid"></i> <span className="align-middle">Cmd. Glovo</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="ui-typography.html">
                            <i className="align-middle" data-feather="align-left"></i> <span className="align-middle">Typography</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="icons-feather.html">
                            <i className="align-middle" data-feather="coffee"></i> <span className="align-middle">Icons</span>
                        </a>
                    </li>

                    <li className="sidebar-header">
                        Plugins & Addons
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="charts-chartjs.html">
                            <i className="align-middle" data-feather="bar-chart-2"></i> <span className="align-middle">Charts</span>
                        </a>
                    </li>

                    <li className="sidebar-item">
                        <a className="sidebar-link" href="maps-google.html">
                            <i className="align-middle" data-feather="map"></i> <span className="align-middle">Maps</span>
                        </a>
                    </li>
                </ul>

              
            </div>
        </nav>
    );
}

export default Sidebar;