import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes,Route } from 'react-router-dom';
import Login from './pages/Login';
import DashBoard from './pages/DashBoard';
import App from './App';
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>      
      <App/>
    </BrowserRouter>
  </React.StrictMode>
);
