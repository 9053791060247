// useAuth.js
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
const navigate = useNavigate();
  useEffect(() => {
    const token = sessionStorage.getItem('token');   
    setIsAuthenticated(true);
    if(!isAuthenticated && !token)
      navigate('/login');
  }, []);

  return isAuthenticated;
};

export default useAuth;
