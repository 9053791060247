import React, { useEffect } from 'react';
import { useState } from 'react';
import Select from 'react-select';
import config from '../config';
import axios from 'axios';
import OpenAI from "openai";


const AddProductWebsite = () => {
  const [categoriesOptions, setCategoriesOption] = useState([]);
  const [productName, setProductName] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [picture, setPicture] = useState(null);
  const openai = new OpenAI({
    apiKey: config.OPENAI ?? null,
    dangerouslyAllowBrowser: true
  });
  useEffect(() => {
    fetchCategory();
  }, [])

  const fetchCategory = () => {


    // Define the headers
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(`${config.API_CLIENT_WEBSITE}:${config.API_SECRET_WEBSITE}`)
    };

    // Use a proxy to handle CORS issues (for development/testing purposes only)

    const targetUrl = `${config.WEBSITE_API}/wp-json/wc/v3/products/categories?per_page=100`;

    axios.get(targetUrl, { headers })
      .then(response => {
        // Handle the response data
        const categories = response.data.map(x => ({ value: x.id, label: x.name }));
        setCategoriesOption(categories);
      })
      .catch(error => {
        // Handle the error
        console.error('Error:', error);
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    const formData = new FormData();
    formData.append('productName', productName);
    formData.append('price', price);
    formData.append('description', description);
    formData.append('categories', selectedCategories.map(cat => cat.value));
    if (picture) {
      formData.append('picture', picture);
    }

    axios.post('https://office.le-celadon.ma/uploadimagesgetUrl.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.data.success) {
          createWooCommerceProduct(response.data.filename);
        } else {
          alert('Error: ' + response.data.error);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    // You can use formData to send the data to your server
  };
  const handlePictureChange = (e) => {
    setPicture(e.target.files[0]);
  };

  const createWooCommerceProduct = (imagePath) => {
    const data = {
      name: productName,
      type: 'simple',
      regular_price: price,
      description: description,
      categories: selectedCategories.map(cat => ({ id: cat.value })),
      images: [
        {
          src: config.DATA_PIC_PATH + "/" + imagePath
        }
      ]
    };


    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Basic ' + btoa(`${config.API_CLIENT_WEBSITE}:${config.API_SECRET_WEBSITE}`)
    };
    axios.post(`${config.WEBSITE_API}/wp-json/wc/v3/products`, data, {
      headers
    })
      .then(response => {
        console.log('Product created:', response.data);
        alert('Product created: ' + response.data.name);
      })
      .catch(error => {
        console.error('Error creating product:', error);
      });
  };
  const generateDescription = async () => {
    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini-2024-07-18",
      messages: ["Hello everr one , answer"],
      temperature: 1,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
    });

    console.log(response);

  }
  return (
    <div className="container mt-0">
      <h1>Ajouter un produit</h1>
      <form onSubmit={handleSubmit} className="container mt-5">
        <div className="mb-3">
          <label htmlFor="productName" className="form-label">Nom du Produit</label>
          <input
            type="text"
            className="form-control"
            id="productName"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="price" className="form-label">Prix</label>
          <input
            type="number"
            className="form-control"
            id="price"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <div className='text-end'><button className='btn btn-dark' onClick={() => generateDescription()}>refresh</button></div>
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            className="form-control"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="categories" className="form-label">Catégories</label>
          <Select
            id="categories"
            isMulti
            options={categoriesOptions}
            value={selectedCategories}
            onChange={setSelectedCategories}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="picture" className="form-label">Picture</label>
          <input
            type="file"
            className="form-control"
            id="picture"
            onChange={handlePictureChange}
          />
        </div>
        <button type="submit" className="btn btn-primary">Submit</button>
      </form>

    </div>
  );
};

export default AddProductWebsite;