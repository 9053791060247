import React, { useEffect, useState } from 'react';


const OrdersGlovo = () => {
  const [activeTab, setActiveTab] = useState('inProgress');
  const [glovoProgrammed, setGlovoProgrammed] = useState([]);
  const [orderFilterAccepted, setOrderFilterAccepted] = useState('');
  const [orderFilterReady, setOrderFilterReady] = useState('');
  const [orderFilterGone, setOrderFilterGone] = useState('');
  const [inProgressOrders, setInProgressOrders] = useState([]);
  const [readyOrders, setReadyOrders] = useState([]);
  const [goneOrders, setGoneOrders] = useState([]);
  const [previewOrderNumber, setPreviewOrderNumber] = useState('');
  const [previewOrderCode, setPreviewOrderCode] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    fetchAll();
    const interval = setInterval(fetchAll, 10000);

    return () => clearInterval(interval);
  }, []);

  const fetchAll = async () => {
    await fetchCurrentOrders('https://api.pegasus.le-celadon.ma/allCurrentOrders', 'accepted', setInProgressOrders);
    await fetchCurrentOrders('https://api.pegasus.le-celadon.ma/getDeliveryWaiting', 'ready', setReadyOrders);
    await fetchCurrentOrders('https://api.pegasus.le-celadon.ma/allGoneOrders', 'gone', setGoneOrders);
    const glovoRes = await fetch('https://api.pegasus.le-celadon.ma/getAllGlovoProgrammed');
    const glovo = await glovoRes.json();
    setGlovoProgrammed(glovo);
  };

  const fetchCurrentOrders = async (url, status, setState) => {
    const res = await fetch(url);
    const data = await res.json();
    setState(data.reverse().filter(row => row.productType === 'Glovo' && row.orderStatus === status));
  };

  const toggleRow = rowId => {
    const element = document.querySelector(`.child-row.${rowId}`);
    if (element) {
      element.style.display = element.style.display === 'none' ? 'table-row' : 'none';
    }
  };

  const changeStatus = (orderNumber, orderCode) => {
    setPreviewOrderNumber(orderNumber);
    setPreviewOrderCode(orderCode);
    setModalVisible(true);
  };

  const handleStatusChange = async (url) => {
    const res = await fetch(url, {
      method: 'post',
      body: JSON.stringify({ orderCode: previewOrderCode }),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (res.status === 200) {
      fetchAll();
      setModalVisible(false);
    } else {
      alert('Error');
    }
  };

  return (
    <div className="container mt-4">
      {/* Nav tabs */}
      <ul className="nav nav-tabs" id="myTabs" role="tablist">
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'inProgress' ? 'active' : ''}`} id="inProgressTab" href="#inProgress" onClick={() => setActiveTab('inProgress')} role="tab" aria-controls="inProgress" aria-selected={activeTab === 'inProgress'}>In Progress</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'ready' ? 'active' : ''}`} id="readyTab" href="#ready" onClick={() => setActiveTab('ready')} role="tab" aria-controls="ready" aria-selected={activeTab === 'ready'}>Ready</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'gone' ? 'active' : ''}`} id="goneTab" href="#gone" onClick={() => setActiveTab('gone')} role="tab" aria-controls="gone" aria-selected={activeTab === 'gone'}>Gone</a>
        </li>
      </ul>

      {/* Tab content */}
      <div className="tab-content mt-2">
        <div className={`tab-pane fade ${activeTab === 'inProgress' ? 'show active' : ''}`} id="inProgress" role="tabpanel" aria-labelledby="inProgressTab">
          <div>
            <label htmlFor="orderFilterAccepted">Filter by Order Number:</label>
            <input type="text" id="orderFilterAccepted" className="form-control"
              value={orderFilterAccepted} onChange={(e) => setOrderFilterAccepted(e.target.value)} />
          </div>
          <table id="orderTableInProgress" className="table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Order Number</th>
                <th>Order Code</th>
                <th>Is prepared</th>
                <th>Number of Products</th>
                <th>Status</th>
                <th>Total</th>
                <th>Expand</th>
              </tr>
            </thead>
            <tbody>
              {inProgressOrders.filter(order => order.orderNumber.includes(orderFilterAccepted)).map((row, index) => (
                <React.Fragment key={index}>
                  <tr className="parent-row" data-row={`accepted${index}`}>
                    <td>{row.orderTime}</td>
                    <td>{row.orderNumber}</td>
                    <td>{row.orderCode}</td>
                    <td>{glovoProgrammed.find(x => x._id === row.orderCode) ? <span className="badge badge-success">Préparé</span> : ''}</td>
                    <td>{row.productsCount}</td>
                    <td>{row.orderStatus.toUpperCase()}</td>
                    <td>{row.products.reduce((acc, product) => acc + parseInt(product.price, 10) || 0, 0)} DHS</td>
                    <td>
                      <button className="btn btn-sm btn-secondary expand-btn" onClick={() => toggleRow(`accepted${index}`)}>Show +</button>
                      <button className="btn btn-info" onClick={() => changeStatus(row.orderNumber, row.orderCode)}>Change Status</button>
                    </td>
                  </tr>
                  <tr className={`child-row accepted${index}`} style={{ display: 'none' }}>
                    <td colSpan="8">
                      {row.products.map((x, i) => (
                        <table className="table" key={i}>
                          <tbody>
                            <tr>
                              <td><small>{row.orderNumber} | {row.orderCode}</small></td>
                              <td><img src={x.urlImage} width="100px" alt={x.title} /></td>
                              <td>{x.quantity}</td>
                              <td colSpan="3">{x.title}</td>
                              <td>{x.options.map((o, j) => (
                                <div key={j}>{o.quantity} {o.title}</div>
                              ))}</td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className={`tab-pane fade ${activeTab === 'ready' ? 'show active' : ''}`} id="ready" role="tabpanel" aria-labelledby="readyTab">
          <div>
            <label htmlFor="orderFilterReady">Filter by Order Number:</label>
            <input type="text" id="orderFilterReady" className="form-control"
              value={orderFilterReady} onChange={(e) => setOrderFilterReady(e.target.value)} />
          </div>
          <table id="orderTableReady" className="table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Order Number</th>
                <th>Order Code</th>
                <th>Is prepared</th>
                <th>Number of Products</th>
                <th>Status</th>
                <th>Total</th>
                <th>Expand</th>
              </tr>
            </thead>
            <tbody>
              {readyOrders.filter(order => order.orderNumber.includes(orderFilterReady)).map((row, index) => (
                <React.Fragment key={index}>
                  <tr className="parent-row" data-row={`ready${index}`}>
                    <td>{row.orderTime}</td>
                    <td>{row.orderNumber}</td>
                    <td>{row.orderCode}</td>
                    <td>{glovoProgrammed.find(x => x._id === row.orderCode) ? <span className="badge badge-success">Préparé</span> : ''}</td>
                    <td>{row.productsCount}</td>
                    <td>{row.orderStatus.toUpperCase()}</td>
                    <td>{row.products.reduce((acc, product) => acc + parseInt(product.price, 10) || 0, 0)} DHS</td>
                    <td>
                      <button className="btn btn-sm btn-secondary expand-btn" onClick={() => toggleRow(`ready${index}`)}>Show +</button>
                      <button className="btn btn-info" onClick={() => changeStatus(row.orderNumber, row.orderCode)}>Change Status</button>
                    </td>
                  </tr>
                  <tr className={`child-row ready${index}`} style={{ display: 'none' }}>
                    <td colSpan="8">
                      {row.products.map((x, i) => (
                        <table className="table" key={i}>
                          <tbody>
                            <tr>
                              <td><small>{row.orderNumber} | {row.orderCode}</small></td>
                              <td><img src={x.urlImage} width="100px" alt={x.title} /></td>
                              <td>{x.quantity}</td>
                              <td colSpan="3">{x.title}</td>
                              <td>{x.options.map((o, j) => (
                                <div key={j}>{o.quantity} {o.title}</div>
                              ))}</td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <div className={`tab-pane fade ${activeTab === 'gone' ? 'show active' : ''}`} id="gone" role="tabpanel" aria-labelledby="goneTab">
          <div>
            <label htmlFor="orderFilterGone">Filter by Order Number:</label>
            <input type="text" id="orderFilterGone" className="form-control"
              value={orderFilterGone} onChange={(e) => setOrderFilterGone(e.target.value)} />
          </div>
          <table id="orderTableGone" className="table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Order Number</th>
                <th>Order Code</th>
                <th>Is prepared</th>
                <th>Number of Products</th>
                <th>Status</th>
                <th>Total</th>
                <th>Expand</th>
              </tr>
            </thead>
            <tbody>
              {goneOrders.filter(order => order.orderNumber.includes(orderFilterGone)).map((row, index) => (
                <React.Fragment key={index}>
                  <tr className="parent-row" data-row={`gone${index}`}>
                    <td>{row.orderTime}</td>
                    <td>{row.orderNumber}</td>
                    <td>{row.orderCode}</td>
                    <td>{glovoProgrammed.find(x => x._id === row.orderCode) ? <span className="badge badge-success">Préparé</span> : ''}</td>
                    <td>{row.productsCount}</td>
                    <td>{row.orderStatus.toUpperCase()}</td>
                    <td>{row.products.reduce((acc, product) => acc + parseInt(product.price, 10) || 0, 0)} DHS</td>
                    <td>
                      <button className="btn btn-sm btn-secondary expand-btn" onClick={() => toggleRow(`gone${index}`)}>Show +</button>
                      <button className="btn btn-info" onClick={() => changeStatus(row.orderNumber, row.orderCode)}>Change Status</button>
                    </td>
                  </tr>
                  <tr className={`child-row gone${index}`} style={{ display: 'none' }}>
                    <td colSpan="8">
                      {row.products.map((x, i) => (
                        <table className="table" key={i}>
                          <tbody>
                            <tr>
                              <td><small>{row.orderNumber} | {row.orderCode}</small></td>
                              <td><img src={x.urlImage} width="100px" alt={x.title} /></td>
                              <td>{x.quantity}</td>
                              <td colSpan="3">{x.title}</td>
                              <td>{x.options.map((o, j) => (
                                <div key={j}>{o.quantity} {o.title}</div>
                              ))}</td>
                            </tr>
                          </tbody>
                        </table>
                      ))}
                    </td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>


      {modalVisible && (
        <div className="modal fade show d-block" id="yourModal" tabIndex="-1" role="dialog" aria-labelledby="yourModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="yourModalLabel">Your Modal Title</h5>
                <button type="button" className="close" onClick={() => setModalVisible(false)} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <h2>Order Number : <span>{previewOrderNumber}</span></h2>
                <h3>Order Code : <span>{previewOrderCode}</span></h3>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-lg" onClick={() => handleStatusChange('https://api.pegasus.le-celadon.ma/toAcceptedGlovo')}>Processing</button>
                <button type="button" className="btn btn-warning btn-lg" onClick={() => handleStatusChange('https://api.pegasus.le-celadon.ma/endOrder')}>Ready</button>
                <button type="button" className="btn btn-success btn-lg" onClick={() => handleStatusChange('https://api.pegasus.le-celadon.ma/toGoneGlovo')}>Delivered</button>
                <button type="button" className="btn btn-secondary" onClick={() => setModalVisible(false)}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrdersGlovo;
