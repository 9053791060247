const config = {
    apiOffice: "https://office.le-celadon.ma/send/excelDolibaar/process.php",
    clients: [
        {
            name: "oflorist",
            site: 31,
            tpe: 67,
            url_erp: "https://accounting.office.le-celadon.ma/"
        }
    ],
    pegasus_api_url: "https://api.pegasus.le-celadon.ma",
    API_CLIENT_WEBSITE: 'ck_3b49e487ccef24f6cfb0837ff91188b865237025',
    API_SECRET_WEBSITE: 'cs_ffde6cb93c099ca23df2721292103ec851bb06b2',
    WEBSITE_API: 'https://le-celadon.ma',
    DATA_PIC_PATH:"https://office.le-celadon.ma",
    TRACKING_VEHICULE:"[8994,9044,9045,9046,9048,8985,8986,8988,8990,8991,9047,8987,8989,8993,8995,8996,8997,8998,8999,9000,9001,9002,9003]"
}

export default config;
