import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import Login from '../pages/Login';


const PrivateRoute = ({ children}) => {

  
  
  return useAuth() ? (
    <div className="wrapper">
    <Sidebar />
    <div className='main'>
      <Navbar />
      <main className="content">
        <div className="container-fluid p-0">
          
        {children}
        </div>
      </main>
      <Footer />
    </div>
  </div>
  
  
  ) :<></>;
};

export default PrivateRoute;
