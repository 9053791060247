import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../components/useAuth';
function Login(props) {

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [isConnected,setIsConnected] = useState(false);
    const auth = useAuth();
    const handleSubmit = (event) => {
      event.preventDefault();
  
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
  
      const raw = JSON.stringify({
        login: login,
        password: password,
      });
  
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

  
      fetch('https://accounting.office.le-celadon.ma/api/index.php/login?DOLAPIKEY=NNKQC51zZ52dMJuv7jQdxt5Mk89bR0t5', requestOptions)
        .then((response) => response.json())
        .then((result) => {
            
            if(result.success && result.success.code==200){
                sessionStorage.setItem('token', result.success.token);
                 setIsConnected(true);
                 console.log("is connected");
                
                
            }else{
                alert(result.error.message);
            }
        })    
        .catch((error) => console.error(error));
    };
  
    useEffect(() => {
        if (isConnected || auth) {
          navigate('/dashboard');
          console.log(auth);
        }
      }, [isConnected]);

    return (
        <div className='container mt-0' style={{backgroundImage:"url('/adminKit/img/photos/office.jpg')"}}>
            <div className="d-flex justify-content-center align-items-center vh-100">
                <div className="p-5 bg-light rounded shadow" style={{padding: "100px"}}>
                    <h2 className="text-center mb-4">Login</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label for="email">Email address:</label>
                            <input type="text" className="form-control" id="email" placeholder="Enter email"  value={login}
                            onChange={(e) => setLogin(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label for="pwd">Password:</label>
                            <input type="password" className="form-control" id="pwd" placeholder="Enter password"  value={password}
                            onChange={(e) => setPassword(e.target.value)} />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">Submit</button>
                    </form>
                </div>
            </div>

        </div>
    );
}

export default Login;